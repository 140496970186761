<template>
    <div>
        <v-card flat>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="12" :md="!selectedNotification ? 12 : 6">
                        <v-card flat>
                            <v-progress-linear indeterminate color="primary" v-if="loading"></v-progress-linear>
                            <v-toolbar flat v-if="!loading">
                                <v-spacer></v-spacer>
                                <v-chip v-if="category=='Inspection'" @click="viewMySubmissions = !viewMySubmissions" :color="viewMySubmissions?'green':null" outlined>
                                    <v-icon small left v-if="viewMySubmissions">check</v-icon>
                                    <v-icon v-else small left>person</v-icon>
                                    View My Submissions</v-chip>
                            </v-toolbar>
                            <v-list subheader height="75vh" style="overflow-y: auto">
                                <transition-group :name="'fade'">
                                    <v-list-item v-for="item in filterNotifications" :key="item.id"
                                        @click="selectedNotification = item"
                                        :style="{ 'border-left': selectedNotification && item.id == selectedNotification.id ? '4px solid var(--v-primary-base)' : '' }">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ item.title }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 12px">
                                                {{ item.description }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle style="font-size: 12px" v-if="item.task">
                                                <v-icon :color="getTaskStatus(item.task).color" class="mr-1" small>fiber_manual_record</v-icon> {{
                                                    item.task.status
                                                }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle style="font-size: 12px" v-if="item.shipment">
                                                <v-icon class="mr-1" small>description</v-icon> {{
                                                    item.shipment.shipmentFile.fileNumber
                                                }}
                                            </v-list-item-subtitle>
                                            
                                            <v-list-item-subtitle style="font-size: 12px" v-if="item.task&&item.task.submittedForInspectionBy">
                                               <v-icon small class="mr-1">person</v-icon> Submitted By: {{ item.task.submittedForInspectionBy.firstname }} {{ item.task.submittedForInspectionBy.surname }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle style="font-size: 12px" >
                                                {{ formatTimeAgo(item.createdAt) }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action class="pr-2">
                                            <v-row align="center">
                                                <v-menu offset-y>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon v-bind="attrs" v-on="on">
                                                            <v-icon v-if="item.flag == null">outlined_flag</v-icon>
                                                            <v-icon v-else
                                                                :color="getStatusColour(item.flag)">flag</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-list dense>
                                                        <v-list-item v-for="(status, index) in statuses" :key="index"
                                                            @click="item.flag = status.value, updateNotification(item)"
                                                            style="height: 30px">
                                                            <v-list-item-action>
                                                                <v-icon :color="status.color">{{ status.icon }}</v-icon>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                <v-list-item-title>{{ status.name }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                                <v-btn small icon class="dotted mx-0" @click="changeReadStatus(item)"
                                                    v-if="!item.read">
                                                    <v-icon small>done</v-icon>
                                                </v-btn>
                                                <v-btn small icon class="mx-0" @click="changeReadStatus(item)" v-else
                                                    color="green"> <v-icon>check_circle</v-icon></v-btn>
                                            </v-row>
                                        </v-list-item-action>
                                    </v-list-item>
                                </transition-group>
                                <v-list-item v-if="!loading && filterNotifications.length == 0">
                                <v-list-item-content class="text-center">
                                    <span style="color: grey">No notifications</span>
                                </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="text-center" v-if="selectedNotification">
                        <TaskCard v-if="category == 'Tasks'" :notification="selectedNotification" />
                        <InspectionCard v-else-if="category == 'Inspection'" :notification="selectedNotification" @close="selectedNotification=null" />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>



    </div>
</template>
<script>
import dateFormat from "dateformat";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US')
import InspectionCard from './InspectionCard.vue'

import TaskCard from './TaskCard.vue'
export default {
    props: ['type', 'category', 'teamId'],
    components: {
        InspectionCard,
        TaskCard
    },
    data: () => ({
        loading: false,
        notifications: [],
        items: 1,
        savingNotification: null,
        selectedNotification: null,
        statuses: [
            { name: 'Urgent', color: 'red', icon: 'flag', value: 'Urgent' },
            { name: 'High', color: 'amber', icon: 'flag', value: 'High' },
            { name: 'Normal', color: 'blue lighten-3', icon: 'flag', value: 'Normal' },
            { name: 'Low', color: 'green', icon: 'flag', value: 'Low' },
            { name: 'Clear', color: 'red', icon: 'close', value: null }
        ],
        viewMySubmissions: false
    }),
    watch: {
        category() {
            this.getNotifications()
        },
        type() {
            this.getNotifications()
        },
        teamId() {
            this.getNotifications()
        }
    },
    computed: {
        filterNotifications(){
            let result = this.notifications
            if(this.viewMySubmissions){
                result = result.filter(x=>x.task&&x.task.submittedForInspectionById == this.$store.state.user.id)
            }
            return result
        }
    },
    created() {
        this.getNotifications()
    },
    methods: {
        async changeReadStatus(item) {
            item.read = !item.read
            await this.updateNotification(item)
            this.notifications.splice(this.notifications.findIndex(x => x.id == item.id), 1)
        },
        formatDate(date) {
            let result = null
            if (date) {
                result = dateFormat(date, "dd mmm, yyyy");
            }
            return result
        },
        formatTimeAgo(date) {
            date = new Date(date)
            let difference = new Date().getTime() - date.getTime()
            return timeAgo.format(new Date().getTime() - difference)
        },
        async getNotifications() {
            try {
                this.loading = true
                this.notifications = await this.$API.getNotifications({
                    params: {
                        type: this.type,
                        category: this.category,
                        teamId: this.teamId
                    }
                })
                if(this.notifications.length>0){
                    this.selectedNotification = this.notifications[0]
                }
                this.loading = false
            } catch (error) {
                console.log(error)
            }
        },
        getTaskStatus(task) {
            switch (task.status) {
                case "New":
                    return { name: task.status, icon: "grade", color: "orange" };
                case "Not started":
                    return { name: task.status, icon: "schedule", color: "grey" };
                case "Pending":
                    return { name: task.status, icon: "pending", color: "orange" };
                case "In Progress":
                    return { name: task.status, icon: "sync", color: "blue" };
                case "Complete":
                    return { name: task.status, icon: "task_alt", color: "green" };
                case "File Generated":
                    return { name: task.status, icon: "task", color: "orange" };
                case "Success":
                    return { name: task.status, icon: "task_alt", color: "green" };
                case "Cancelled":
                    return { name: task.status, icon: "cancel", color: "grey" };
                case "Not Created":
                    return { name: task.status, icon: "error", color: "red" };
                case "Rejected":
                    return { name: task.status, icon: "cancel", color: "red" };
                case "Draft":
                    return { name: task.status, icon: "edit_note", color: "orange" };
                case "Error":
                    return { name: task.status, icon: "error", color: "red" };
                case "Failed":
                    return { name: task.status, icon: "cancel", color: "red" };
                case "Submitted":
                    return { name: task.status, icon: "mark_email_read", color: "blue" };
                case "Not Required":
                    return { name: task.status, icon: "close", color: "grey" };
                case "Approved":
                    return { name: task.status, icon: "fact_check", color: "green" };
                default:
                    return {
                        icon: "info",
                        color: "grey",
                        name: task.status,
                    };
            }
        },
        getStatusColour(flag) {
            let find = this.statuses.find(x => x.value == flag)
            return find.color
        },
        async updateNotification(notification) {
            try {
                this.savingNotification = notification.id
                await this.$API.updateNotification(notification)
                this.savingNotification = null
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>
<style>
.dotted {
    border-style: dotted;
}

.outline {
    border: 0.5px solid green;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}
</style>